import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

let routes = [
	{
		path: '/',
		name: 'home',
		// component: () => import('../pages/home.vue'),
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	},
	{
		path: '/home',
		name: 'homepage',
		// component: () => import('../pages/home.vue'),
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	},
	{
		path: '/search',
		name: 'search',
		component: () => import(/* webpackChunkName: "home" */ '../pages/search.vue'),
	},
	{
		path: '/privacypolicy',
		name: 'privacy',
		component: () => import('../pages/privacy.vue'),
	},
	{
		path: '/termsofservice',
		name: 'terms',
		component: () => import('../pages/terms.vue'),
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../pages/about.vue'),
	},
	{
		path: '/landing',
		name: 'landing',
		component: () => import('../pages/landing.vue'),
	},

	{
		// 限制 channel 只能是 channel1, channel2, channel3
		path: '/:channel(channel1|channel2|channel3)',
		name: 'homechannel',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log('访问的是 /' + to.params.channel)
			next()
		},
	},
	{
		// 匹配带有 channel 和 landing 的路径，例如 /channel1/landing
		path: '/:channel(channel1|channel2|channel3)/landing',
		name: 'childChannellandingPage',
		component: () => import(/* webpackChunkName: "landing" */ '../pages/landing.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log('Channel landing:', to.params.channel)
			next()
		},
	},
	{
		// 匹配带有 channel 和 sub 的路径
		path: '/:channel(channel1|channel2|channel3)/:sub',
		name: 'childChannelPage',
		component: () => import(/* webpackChunkName: "childpage" */ '../pages/childpage.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log('Channel:', to.params.channel)
			console.log('Sub:', to.params.sub)
			next()
		},
	},

	{
		// 匹配没有 channel 的 sub 路径，例如 /arts
		path: '/:sub',
		name: 'childpage',
		component: () => import(/* webpackChunkName: "childpage" */ '../pages/childpage.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log('Sub:', to.params.sub)
			next()
		},
	},
	// {
	// 	// 匹配 /channel1 并传递 channel 参数
	// 	path: '/:channel([a-zA-Z0-9]+)',
	// 	name: 'homechannel',
	// 	component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	// 	props: true, // 将 channel 和 sub 传递为 props
	// 	beforeEnter: (to, from, next) => {
	// 		console.log('Channel:', to.params.channel) // 打印 channel 参数
	// 		next()
	// 	},
	// },
	// {
	// 	// 渲染 channel1 下的子页面
	// 	path: '/:channel([a-zA-Z0-9]+)/:sub?',
	// 	name: 'channelWithSub',
	// 	component: () => import(/* webpackChunkName: "childpage" */ '../pages/childpage.vue'),
	// 	props: true, // 将 channel 和 sub 传递为 props
	// 	beforeEnter: (to, from, next) => {
	// 		console.log('Channel:', to.params.channel) // 打印 channel 参数
	// 		console.log('Sub:', to.params.sub) // 打印 sub 参数（如果有）
	// 		next()
	// 	},
	// },
	// {
	// 	// 渲染 channel1 下的子页面
	// 	path: '/:channel([a-zA-Z0-9]+)/landing',
	// 	name: 'homechannelLanding',
	// 	component: () => import('../pages/landing.vue'),
	// 	props: true, // 将 channel 和 sub 传递为 props
	// 	beforeEnter: (to, from, next) => {
	// 		console.log('Channel:', to.params.channel) // 打印 channel 参数
	// 		next()
	// 	},
	// },
	// {
	// 	// 匹配任意的 sub 路径
	// 	path: '/:sub',
	// 	name: 'childpage',
	// 	component: () => import(/* webpackChunkName: "childpage" */ '../pages/childpage.vue'),
	// 	props: true,
	// },
	// {
	// 	// 动态匹配 channel，并且允许为空。默认情况 channel 是空字符串
	// 	path: '/:channel?/landing',
	// 	name: 'channelLanding',
	// 	component: () => import(/* webpackChunkName: "landing" */ '../pages/landing.vue'),
	// 	props: true,
	// },
	// {
	// 	// home 页只匹配 / 或者 /channel1
	// 	path: '/:channel?',
	// 	name: 'homechannel',
	// 	component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	// 	props: (route) => ({ channel: route.params.channel || '' }), // 默认 channel 为空
	// 	beforeEnter: (to, from, next) => {
	// 		console.log('Channel:', to.params.channel || 'No Channel') // 打印 channel 参数，如果为空则表示没有 channel
	// 		next()
	// 	},
	// },
	// {
	// 	// 动态匹配子路径 sub，可以是任意值
	// 	path: '/:channel?/:sub',
	// 	name: 'childChannelPage',
	// 	component: () => import(/* webpackChunkName: "childpage" */ '../pages/childpage.vue'),
	// 	props: true, // 将 channel 和 sub 参数传递到组件
	// 	beforeEnter: (to, from, next) => {
	// 		console.log('Channel:', to.params.channel || 'No Channel') // 打印 channel 参数
	// 		console.log('Sub:', to.params.sub) // 打印 sub 参数
	// 		next()
	// 	},
	// },
]

export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
